<template>
  <b-overlay>
    <b-card>
      <div>
        <b-check v-model="showOrdersInPlans" inline class="float-left">Prikaži tudi naročila v planih</b-check>
        <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
        <b-table striped responsive bordered hover :items="filtered" :fields="fields" :filter="search"
                 :per-page="perPage" :current-page="currentPage" filter-debounce="500">
          <template slot="top-row" slot-scope="{ fields }">
            <b-td v-for="field in fields" :key="field.key">
              <b-form-input v-if="field.key !== 'actions'" size="sm" v-model="filters[field.key]"
                            :placeholder="field.label"/>
            </b-td>
          </template>
          <template v-slot:cell(status)="row">
            <template v-if="row.item.status === 'V planu'">
              <b-col @click="$router.push({path: `/plan/${row.item.plan_id}`})">
                <b-badge variant="success">{{ row.item.status }}</b-badge>
               <div>{{ row.item.plan_bcid }}</div>
              </b-col>
            </template>
            <b-badge v-else-if="row.item.status === 'Na čakalni listi'" variant="primary">{{
                row.item.status
              }}
            </b-badge>
            <b-badge v-else-if="row.item.status === 'Vnos' || row.item.status === 'Vnešeno'" variant="info">
              {{ row.item.status }}
            </b-badge>
            <b-badge v-else variant="danger">{{ row.item.status }}</b-badge>
          </template>
          <template v-slot:cell(selected)="row">
            <b-check v-model="row.item.selected"></b-check>
          </template>
          <template #cell(actions)="row">
            <div class="d-flex">
              <b-button class="mr-1 float-left" @click="$refs.orderModal.open(row.item)" variant="success">
                <fa icon="eye"/>
              </b-button>
              <b-button class="float-right" v-if="row.item.status !== 'Na čakalni listi'"
                        @click="addToWaitingList(row.item)">
                <fa icon="pause-circle"/>
              </b-button>
            </div>
          </template>
          <template v-slot:cell(date_due)="row">
            <div v-if="new Date(row.item.date_due).toLocaleTimeString('sl') === '00:00:00'">
              {{ new Date(row.item.date_due).toLocaleDateString("sl") }}
            </div>
            <div v-else>{{
                new Date(row.item.date_due).toLocaleDateString("sl") + " " + new Date(row.item.date_due).toLocaleTimeString("sl")
              }}
            </div>
          </template>
          <template v-slot:cell(plan_date)="row">
            <template v-if="row.item.plan_date !== '0001-01-01T00:00:00Z'">
              <div @click="$router.push({path: `/plan/${row.item.plan_id}`})">
              {{ new Date(row.item.plan_date).toLocaleDateString("sl") }}
              </div>
            </template>
            <template v-else>
              Neplaniran
            </template>
          </template>
        </b-table>
        <b-row>
          <b-col class="float-left">
            <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
              <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                               @click="setPerPage(item)">
                {{ item }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="7" md="6" class="float-right">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <OrderModal ref="orderModal"/>
  </b-overlay>
</template>

<script>
    import OrderModal from '@/views/Narocilo'
    import fuzzysort from 'fuzzysort'

    export default {
        components: {
            OrderModal
        },
        data() {
            return {
                showOrdersInPlans: true,
                selectedDate: new Date().toISOString().substr(0, 10),
                dateFormat: {'year': 'numeric', 'month': 'numeric', 'day': 'numeric'},
                selectedItem: {},
                all_orders: [],
                orders_not_in_plans: [],
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                selected: {},
                fields: [
                    {key: 'id', label: 'ID', sortable: true, class: 'text-center'},
                    {key: 'client', label: 'Stranka', sortable: true, class: 'text-center'},
                    {key: 'date_due', label: 'Zapadlost', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'},
                    {key: 'sales_man_code', label: 'Tržnik', sortable: true, class: 'text-center'},
                    {key: 'status', label: 'Status', sortable: true, class: 'text-center'},
                    {key: 'plan_date', label: 'Planiran datum', sortable: true, class: 'text-center'},
                    {key: 'material_type.id', label: 'Material šifra', sortable: true, class: 'text-center'},
                    {key: 'material_type.material', label: 'Material naziv', sortable: true, class: 'text-center'},
                    {key: 'actions', label: ''}
                ],
                filters: {
                    'id': '',
                    'client': '',
                    'date_added': '',
                    'date_due': '',
                    'quantity': '',
                    'status': '',
                    'plan_date': '',
                    'material_type.id': '',
                    'material_type.material': ''
                }
            }
        },
        methods: {
            getOrders() {
                const thisIns = this
                this.$http.get('/api/v1/user/order')
                    .then(function(response) {
                        thisIns.all_orders = response.data

                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
                this.$http.get('/api/v1/user/orders_not_in_plans')
                    .then(function(response) {
                        thisIns.orders_not_in_plans = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            setPerPage(item) {
                this.perPage = item
            },
            addToWaitingList(order) {
                const thisIns = this
                this.$http.post(`/api/v1/user/waiting_list/${order.id}`)
                    .then(function() {
                        order.status = 'Na čakalni listi'
                        thisIns.$printSuccess('Naročilo dodano na čakalno listo')
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            flatten(obj, prefix = [], current = {}) {
                if (typeof (obj) === 'object' && obj !== null) {
                    for (const key of Object.keys(obj)) {

                        this.flatten(obj[key], prefix.concat(key), current)
                    }
                } else if (prefix[0] === 'plan_date') {
                    current[prefix.join('.')] = new Date(obj).toLocaleDateString('sl')
                } else {
                    current[prefix.join('.')] = obj
                }
                return current
            }
        },
        computed: {
            totalRows() {
                return this.filtered.length
            },
            filtered() {
                let filtered = []
                if (!this.showOrdersInPlans) {
                    filtered = this.orders_not_in_plans.filter(item => {
                        return Object.keys(this.filters).every(key => String(this.flatten(item)[key]).toLowerCase().includes(this.filters[key].toLowerCase()) || fuzzysort.single(this.filters[key].toLowerCase(), String(this.flatten(item)[key]).toLowerCase()) !== null)
                    })
                } else {
                    filtered = this.all_orders.filter(item => {
                        return Object.keys(this.filters).every(key => String(this.flatten(item)[key]).toLowerCase().includes(this.filters[key].toLowerCase()) || fuzzysort.single(this.filters[key].toLowerCase(), String(this.flatten(item)[key]).toLowerCase()) !== null)
                    })
                }


                return filtered.length > 0 ? filtered : [
                    {
                        id: '',
                        client: '',
                        date_added: '',
                        date_due: '',
                        quantity: '',
                        status: ''
                    }
                ]
            },
            planningStatus() {
                return this.$store.state.user.planning
            }
        },
        watch: {
            planningStatus() {
                this.getOrders()
            }
        },
        created() {
            this.getOrders()
        }
    }
</script>

<style>

</style>
